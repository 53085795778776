import React from "react";
import AuthWrapper from "common/AuthWrapper";
import { useFormik } from "formik";
import AuthInputFieldWrapper from "common/AuthInputFieldWrapper";
import { Email, PasswordOpen } from "assets/svgs/authSvgs";
import Logo from "assets/images/Logo.png";
import * as Yup from "yup";
import { OyaQueryApi } from "api/QueryApi";
import useAuth from "hooks/useAuth";
import ButtonLoader from "common/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAppDispatch } from "hooks/reduxHooks";
import { setOtpPayload } from "features/authSlice";

const ForgotPassword = () => {
  const token = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getOtpMutation, getOtpMutationResults] = OyaQueryApi.useGetOtpMutation();
  const formik = useFormik({
    initialValues: {
      path: "",
      //   password: "",
    },
    validationSchema: Yup.object().shape({
      path: Yup.string().required("Email or Phone number is required"),
      //   password: Yup.string().required("Password required"),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const result = await getOtpMutation(values).unwrap();
        // console.log("login results", result);
        toast.success(result?.message);
        dispatch(setOtpPayload(formik?.values?.path));
        setTimeout(() => {
          navigate("/reset-password");
        }, 300);
      } catch (error: any) {
        toast.error(error?.data?.message);
      }
    },
  });

  return token ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <AuthWrapper>
      <div className="flex justify-between h-full w-full">
        <div className="flex flex-col lg:w-1/2 w-full justify-center px-[150px]">
          <p className="text-white text-[32px] mb-[27px]">Forgot password?</p>
          <p className="text-[#C3C3C3] pb-3">Enter your email address to reset your password</p>
          <div>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full pb-1">
                  <label htmlFor="">Email</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    <input className="bg-inherit text-[#C4C6CB] outline-none w-full" {...formik.getFieldProps("path")} type="text" placeholder="Enter your email address or Phone number" />
                    <div>
                      <Email />
                    </div>
                  </div>
                </div>
              </AuthInputFieldWrapper>
              {formik.touched.path && formik.errors.path ? <div className="text-red-600 text-xs pb-3 px-2">{formik.errors.path}</div> : null}
              <div className="mb-[24px]"></div>

              <div>
                <button className="bg-gradient-to-br from-[#FF3800] flex items-center justify-center to-[#EE7930] rounded-[20px] text-white py-4 px-8 w-full" type="submit">
                  {getOtpMutationResults.isLoading ? <ButtonLoader /> : "Get Reset Link"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex w-1/2 justify-end items-end p-[50px]">
          <div className="bg-white max-w-[276px] py-[10px] w-fit h-fit px-[16px] rounded-lg">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
