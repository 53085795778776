import React, { useState } from "react";
import AuthWrapper from "common/AuthWrapper";
import { useFormik } from "formik";
import AuthInputFieldWrapper from "common/AuthInputFieldWrapper";
import { Email, PasswordOpen } from "assets/svgs/authSvgs";
import Logo from "assets/images/Logo.png";
import * as Yup from "yup";
import { OyaQueryApi } from "api/QueryApi";
import useAuth from "hooks/useAuth";
import ButtonLoader from "common/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { FaEyeSlash } from "react-icons/fa";
import toast from "react-hot-toast";

const Login = () => {
  const token = useAuth();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const [loginMutation, loginMutationResults] = OyaQueryApi.useLoginMutation();
  const formik = useFormik({
    initialValues: {
      path: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      path: Yup.string().required("Email or Phone number is required"),
      password: Yup.string().required("Password required"),
    }),
    onSubmit: async (values, formikHelpers) => {
      try {
        const result = await loginMutation(values).unwrap();
        console.log("login results", result);
      } catch (error: any) {
        toast.error(error?.data?.message);
      }
    },
  });

  return token ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <AuthWrapper>
      <div className="flex justify-between h-full w-full">
        <div className="flex flex-col xl:w-1/2 lg:w-[2/3] w-full justify-center px-[150px]">
          <p className="text-[#C3C3C3] pb-3">Hello! Welcome back</p>
          <p className="text-white text-[32px] mb-[27px]">Login to your dashboard</p>
          <div>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full pb-1">
                  <label htmlFor="">Email</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    <input className="bg-inherit text-[#C4C6CB] outline-none w-full" {...formik.getFieldProps("path")} type="text" placeholder="Enter your email address or Phone number" />
                    <div>
                      <Email />
                    </div>
                  </div>
                </div>
              </AuthInputFieldWrapper>
              {formik.touched.path && formik.errors.path ? <div className="text-red-600 text-xs pb-3 px-2">{formik.errors.path}</div> : null}
              <div className="mb-[24px]"></div>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full">
                  <label htmlFor="">Password</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    <input className="bg-inherit text-[#C4C6CB] outline-none w-full" type={passwordVisible ? "text" : "password"} {...formik.getFieldProps("password")} placeholder="Enter your password" />
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setPasswordVisible(!passwordVisible);
                      }}
                    >
                      {!passwordVisible ? <PasswordOpen /> : <FaEyeSlash />}
                    </div>
                  </div>
                </div>
              </AuthInputFieldWrapper>
              {formik.touched.password && formik.errors.password ? <div className="text-red-600 text-xs pb-3 px-2">{formik.errors.password}</div> : null}
              <div className="mt-5 text-[#C3C3C3] mb-5 flex justify-end items-center">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password?
                </p>
              </div>
              <div>
                <button className="bg-gradient-to-br from-[#FF3800] flex items-center justify-center to-[#EE7930] rounded-[20px] text-white py-4 px-8 w-full" type="submit">
                  {loginMutationResults.isLoading ? <ButtonLoader /> : "Login"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex xl:w-1/2 lg:w-[1/3] justify-end items-end p-[50px]">
          <div className="bg-white max-w-[276px] py-[10px] w-fit h-fit px-[16px] rounded-lg">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
