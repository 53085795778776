export const AuthEndPoints = {
  LOGIN: "auth/login/",
  SAMPLE_LOGIN: "auth/user_login",
};

export const UserEndpoints = {
  // riders
  GET_RIDERS: "users/riders/",
  CREATE_RIDER: "users/riders/",
  DELETE_RIDER: "users/riders/",

  // drivers
  GET_DRIVERS: "users/drivers",
  GET_DRIVER_BY_ID: "drivers/",
  ADD_DRIVER: "users/drivers",
  DELETE_DRIVER: "users/drivers",

  // admins
  GET_ADMINS: "users/admin/",
  CREATE_ADMIN: "users/admin/",
  DELETE_ADMIN: "users/admin/",
};

export const RidesEndpoints = {
  GET_DRIVER_RIDES_BY_ID: "rides/",
};

export const PaymentEndpoints = {
  GET_CONFIG_PAYMENT: "config/payment",
  UPDATE_CONFIG_PAYMENT: "config/payment",
};

export const FAQEndpoints = {
  GET_FAQs: "faq",
  CREATE_FAQ: "faq/",
  GET_FAQ_BY_ID: "faq/",
  EDIT_FAQ_BY_ID: "faq/",
  DELETE_FAQ_BY_ID: "faq/",
};

export const CouponEndpoints = {
  GET_COUPONS: "coupons",
  CREATE_COUPON: "coupons/",
  GET_COUPON_BY_ID: "coupons/",
  EDIT_COUPON_BY_ID: "coupons/",
  DELETE_COUPON_BY_ID: "coupons/",
};

export const ComplaintsEndpoints = {
  GET_COMPLAINTS: "complaints",
  CREATE_COMPLAINT: "complaints/",
  GET_COMPLAINT_BY_ID: "complaints/",
  EDIT_COMPLAINT_BY_ID: "complaints/",
  DELETE_COMPLAINT_BY_ID: "complaints/",
};

export const DashBoradEndpoints = {
  INCOME_STATS: "dashboard/income-stats/",
  RECENT_RIDES: "dashboard/recent-rides/",
  RIDE_STATS: "dashboard/rides-stats/",
  TOTAL_DRIVERS: "dashboard/total-drivers/",
  TOTAL_REVENUE: "dashboard/total-revenue/",
  TOTAL_RIDES: "dashboard/total-rides/",
  TOTAL_USERS: "dashboard/total-users/",
};

export const AuditTrailEndpoints = {
  GET_AUDIT_TRAIL: "audit-trail/",
};

export const DriverDocumentEndpoints = {
  PATCH_DOCUMENT: "vehicle-documents/",
  UPDATE_DOCUMENT: "vehicle-documents/",
  PATCH_LICENSE: "licenses/",
  VERIFY_DRIVER: `drivers/`,
};

export const BankEndpoints = {
  GET_DRIVER_BANK_ACCOUNTS: "bank-account/",
};

export const ReportEndpoints = {
  GET_REPORT_STATS: "payments/transactions/stats/",
  GET_REPORTS: "payments/transactions/",
};
