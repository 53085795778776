import React, { useState } from "react";
import AuthWrapper from "common/AuthWrapper";
import { useFormik } from "formik";
import AuthInputFieldWrapper from "common/AuthInputFieldWrapper";
import { LockSvg } from "assets/svgs/authSvgs";
import Logo from "assets/images/Logo.png";
import * as Yup from "yup";
import { OyaQueryApi } from "api/QueryApi";
import useAuth from "hooks/useAuth";
import ButtonLoader from "common/ButtonLoader";
import { Navigate, useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
import toast from "react-hot-toast";

const ResetPassword = () => {
  const token = useAuth();
  const navigate = useNavigate();
  const { otpPayload } = useAppSelector((store) => store.auth);
  const [code, setCode] = useState("");

  const [resetMutation, resetMutationResults] = OyaQueryApi.useResetPasswordMutation();
  const [verifyOtpMutation, verifyOtpMutationResults] = OyaQueryApi.useVerifyOtpMutation();

  // console.log(otpPayload);

  const formik = useFormik({
    initialValues: {
      path: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object().shape({
      // path: Yup.string().required("Email or Phone number is required"),
      password: Yup.string().required("Password required"),
      confirm_password: Yup.string().required("Password required"),
    }),
    onSubmit: async (values) => {
      try {
        const OtpResult = await verifyOtpMutation({ path: otpPayload, code: code }).unwrap();
        if (OtpResult) {
          try {
            console.log(values);

            const result = await resetMutation({ ...values, path: otpPayload }).unwrap();
            console.log("login results", result);
          } catch (error: any) {
            toast.error(error?.data?.message);
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.message);
        console.log(error);
      }
    },
  });

  return token ? (
    <Navigate to={"/dashboard"} />
  ) : (
    <AuthWrapper>
      <div className="flex justify-between h-full w-full">
        <div className="flex flex-col lg:w-1/2 w-full justify-center px-[150px]">
          <p className="text-white text-[32px] mb-[27px]">Reset Password</p>
          <p className="text-[#C3C3C3] pb-3 mb-[1rem]">Create a new password</p>
          <div>
            <form className="w-full" onSubmit={formik.handleSubmit}>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full pb-1">
                  <label htmlFor="">Otp Code</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    {/* <div className="cursor-pointer">{<LockSvg />}</div> */}
                    <input
                      className="bg-inherit text-[#C4C6CB] outline-none w-full"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                      type="text"
                      placeholder="enter otp code"
                    />
                  </div>
                </div>
              </AuthInputFieldWrapper>
              <div className="mb-[24px]"></div>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full pb-1">
                  <label htmlFor="">New Password</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    <div className="cursor-pointer">{<LockSvg />}</div>
                    <input className="bg-inherit text-[#C4C6CB] outline-none w-full" {...formik.getFieldProps("password")} type="password" placeholder="************" />
                  </div>
                </div>
              </AuthInputFieldWrapper>
              {formik.touched.password && formik.errors.password ? <div className="text-red-600 text-xs pb-3 px-2">{formik.errors.password}</div> : null}
              <div className="mb-[24px]"></div>
              <AuthInputFieldWrapper>
                <div className="flex flex-col w-full">
                  <label htmlFor="">Confirm Password</label>
                  <div className="flex items-center w-full mt-1 justify-between">
                    <div className="cursor-pointer">{<LockSvg />}</div>
                    <input className="bg-inherit text-[#C4C6CB] outline-none w-full" type={"password"} {...formik.getFieldProps("confirm_password")} placeholder="************" />
                  </div>
                </div>
              </AuthInputFieldWrapper>
              {formik.touched.confirm_password && formik.errors.confirm_password ? <div className="text-red-600 text-xs pb-3 px-2">{formik.errors.confirm_password}</div> : null}
              <div className="mt-5 text-[#C3C3C3] mb-5 flex justify-end items-center">
                <p
                  className="cursor-pointer"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot Password?
                </p>
              </div>
              <div>
                <button className="bg-gradient-to-br from-[#FF3800] flex items-center justify-center to-[#EE7930] rounded-[20px] text-white py-4 px-8 w-full" type="submit">
                  {resetMutationResults.isLoading || verifyOtpMutationResults.isLoading ? <ButtonLoader /> : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex w-1/2 justify-end items-end p-[50px]">
          <div className="bg-white max-w-[276px] py-[10px] w-fit h-fit px-[16px] rounded-lg">
            <img src={Logo} alt="" />
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
